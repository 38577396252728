import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "P:/falqon/ortoapp/ortoapp/frontend/support/app_support/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "como-que-eu-faço"
    }}>{`Como que eu faço?`}</h1>
    <h2 {...{
      "id": "editar-meu-perfil"
    }}>{`Editar meu perfil`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=ZBNKfWfWUHA"
      }}><img src="https://img.youtube.com/vi/ZBNKfWfWUHA/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "agendar-uma-consulta-presencial"
    }}>{`Agendar uma consulta presencial`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=Ct5yMlGkOXk"
      }}><img src="https://img.youtube.com/vi/Ct5yMlGkOXk/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "agendar-uma-conversa-virtual"
    }}>{`Agendar uma conversa virtual`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=5KtsM4MUz08"
      }}><img src="https://img.youtube.com/vi/5KtsM4MUz08/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "habilitar-notificações-push-notifications"
    }}>{`Habilitar notificações (push notifications)`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=xk4pHzAJcG0"
      }}><img src="https://img.youtube.com/vi/xk4pHzAJcG0/0.jpg" width="300" /></a></p>
    <h2 {...{
      "id": "adicionar-um-médico"
    }}>{`Adicionar um médico`}</h2>
    <p>{`Clique na imagem abaixo para assistir o vídeo instrucional:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=AKBoSVKOSEE"
      }}><img src="https://img.youtube.com/vi/AKBoSVKOSEE/0.jpg" width="300" /></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      